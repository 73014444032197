import React from "react"
import Layout from "components/layout"
import Footer from "components/footer"
import SEO from "components/seo"
import { Link, graphql } from "gatsby"

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <li key={post.node.fields.slug}>
        <Link className="tag tag-link" to={post.node.fields.slug}>
          <h2 className="is-size-2">{post.node.frontmatter.title}</h2>
        </Link>
      </li>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } tagged with “${tag}”`

    return (
      <div className="body">
        <Layout link="blog">
          <SEO
            title={`${tag} | ${title}`}
            description="My blog tags page"
            keywords="Blog,Tags"
          />

          <main id="tags">
            <h1 className="lg-heading" style={{ lineHeight: "1.7" }}>
              Tags
              <span className="text-secondary"></span>
            </h1>
            <h2 className="sm-heading">
              <div className="container content">
                <div className="columns">
                  <div className="column ">
                    <h3>{tagHeader}</h3>
                    <ul className="taglist">{postLinks}</ul>
                    <p>
                      <br />
                      <br />
                      <Link className="tag tag-link" to="/tags/">
                        Browse all tags
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </h2>
          </main>
        </Layout>
        <Footer />
      </div>
      // <section className="section">
      //   <Helmet title={`${tag} | ${title}`} />
      //   <div className="container content">
      //     <div className="columns">
      //       <div
      //         className="column is-10 is-offset-1"
      //         style={{ marginBottom: '6rem' }}
      //       >
      //         <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
      //         <ul className="taglist">{postLinks}</ul>
      //         <p>
      //           <Link to="/tags/">Browse all tags</Link>
      //         </p>
      //       </div>
      //     </div>
      //   </div>
      // </section>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
